// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-asiakkaat-js": () => import("./../../../src/pages/asiakkaat.js" /* webpackChunkName: "component---src-pages-asiakkaat-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-lisatoiminnot-js": () => import("./../../../src/pages/lisatoiminnot.js" /* webpackChunkName: "component---src-pages-lisatoiminnot-js" */),
  "component---src-pages-tuotanto-js": () => import("./../../../src/pages/tuotanto.js" /* webpackChunkName: "component---src-pages-tuotanto-js" */),
  "component---src-pages-varasto-js": () => import("./../../../src/pages/varasto.js" /* webpackChunkName: "component---src-pages-varasto-js" */)
}

